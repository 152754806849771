import { OrgCoupon, OrgCouponType, OrgCouponUseType, OrgId, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import moment from "moment";

export async function orgCoupon__server__getOrgCouponNoAuth(p: { code: string; orgId: OrgId; useType: OrgCouponUseType }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const couponDocs = (
    await h.OrgCoupon.query({
      where: [
        {
          code: ["==", p.code]
        },
        {
          orgId: ["==", p.orgId]
        }
      ]
    })
  ).docs;
  const filteredDocs = couponDocs.length
    ? couponDocs.filter(coupon => coupon.useType === OrgCouponUseType.all || coupon.useType === p.useType)
    : [];
  return filteredDocs.length ? filteredDocs[0] : undefined;
  // SERVER_ONLY_TOGGLE
}

orgCoupon__server__getOrgCouponNoAuth.auth = async (req: any) => {};

// i18n certified - complete
