import { OrgCoupon } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgCoupon__client__addOrgCoupon(p: {
  orgCoupon: DistributiveOmit<OrgCoupon, "id" | "createdAtMS">;
  locale: string;
}): Promise<{ type: "success" } | { type: "error"; message: string }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const existingCoupon = await h.OrgCoupon.query({
    where: [
      {
        orgId: ["==", p.orgCoupon.orgId]
      },
      {
        code: ["==", p.orgCoupon.code]
      }
    ]
  });

  if (existingCoupon.docs.length) {
    return {
      type: "error",
      message: translate({ defaultMessage: "A coupon with that code already exists for your organziation." })
    };
  }

  const newOrgCoupon: OrgCoupon = { ...p.orgCoupon, id: h.OrgCoupon.generateId(), createdAtMS: Date.now() };
  await h.OrgCoupon.add({
    doc: newOrgCoupon
  });
  return { type: "success" };
}

// i18n certified - complete
