import { OrgCouponId, OrgId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  firestoreLiftDocSubToBifrostSub,
  firestoreLiftQuerySubToBifrostSub
} from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgCoupon__client__getOrgCouponSubscription(p: { orgCouponId: OrgCouponId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocSubToBifrostSub(h.OrgCoupon.docSubscription(p.orgCouponId));
}

// i18n certified - complete
