import { OrgCoupon, OrgCouponId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgCoupon__client__deleteOrgCoupon(p: { orgCouponId: OrgCouponId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.OrgCoupon.delete({ id: p.orgCouponId });
}

// i18n certified - complete
