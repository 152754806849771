import { OrgCoupon, PlayerBundleId } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";
import moment from "moment";

export async function orgCoupon__server__getAvailableOrgCouponsForPlayerBundleIdNoAuth(p: { playerBundleId: PlayerBundleId }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const allOrgCouponsForPlayerBundle = (
    await h.OrgCoupon.query({ where: [{ playerBundleIds: { [p.playerBundleId]: ["==", true] } }] })
  ).docs;

  return allOrgCouponsForPlayerBundle.filter(oc => {
    if (oc.expirationDateMS && moment().valueOf() > oc.expirationDateMS) {
      return false;
    }
    if (oc.useLimit && oc.playerBundleIds && (oc.numberTimesUsedByPlayerBundleId?.[p.playerBundleId] || 0) > oc.useLimit) {
      return false;
    }
    return true;
  });
  // SERVER_ONLY_TOGGLE
}

orgCoupon__server__getAvailableOrgCouponsForPlayerBundleIdNoAuth.auth = async (req: any) => {};

// i18n certified - complete
