import { OrgCoupon, OrgCouponType } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgCoupon__client__updateOrgCoupon(p: {
  orgCoupon: OrgCoupon;
  locale: string;
}): Promise<{ type: "success" } | { type: "error"; message: string }> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const existingCoupon = await h.OrgCoupon.query({
    where: [
      {
        orgId: ["==", p.orgCoupon.orgId]
      },
      {
        code: ["==", p.orgCoupon.code]
      }
    ]
  });

  if (existingCoupon.docs.length && existingCoupon.docs[0].id !== p.orgCoupon.id) {
    return {
      type: "error",
      message: translate({ defaultMessage: "A coupon with that code already exists for your organziation." })
    };
  } else if (!p.orgCoupon.code) {
    return {
      type: "error",
      message: translate({ defaultMessage: "Coupon must have a code" })
    };
  }
  await h.OrgCoupon.set({
    id: p.orgCoupon.id,
    doc: p.orgCoupon
  });
  return { type: "success" };
}

// i18n certified - complete
